.work {
  padding: 0 70px;
  margin-top: 70px;
}
.timeline {
  display: flex;
}
.timeline h3 {
  color: var(--text-color-secondary);
}
.timelineItem:first-child {
  padding-top: 3px;
}
.timelineItem {
  position: relative;
  padding: 25px 0 20px 40px;
}
.timelineItem:before {
  content: '';
  position: absolute;
  display: block;
  height: 100%;
  background-color: var(--timeline-item-after-color);
  width: 1px;
  left: 15px;
  bottom: 5px;
}
.timelineItem:after {
  content: '';
  position: absolute;
  display: block;
  height: 1px;
  background-color: var(--timeline-item-after-color);
  width: calc(100% - 15px);
  left: 15px;
  bottom: 3px;
}
.timelineItem h5.itemPeriod {
  display: inline-block;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 5px -40px;
  color: var(--text-color-gray);
  background-color: var(--item-period-bg);
  padding: 0 10px;
  line-height: 23px;
  border: 2px solid #007ced;
  border-radius: 30px;
}
.timelineItem .itemCompany {
  display: inline-block;
  font-size: 12px;
  color: var(--item-company);
  opacity: 0.7;
  margin-left: 5px;
}
.timelineItem .itemTitle {
  font-size: 16px;
  margin: 10px 0 7px;
  color: var(--text-color-secondary);
}
.timelineItem p {
  font-size: 14px;
  font-size: 14px;
  color: var(--text-color-gray);
  margin: 0 0 10px;
  white-space: pre-line;
}

@media only screen and (max-width: 430px) {
  .work {
    padding: 0 30px;
    margin-top: 50px;
  }
  .timeline {
    flex-direction: column;
  }
}
