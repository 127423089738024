* {
  transition: background-color 0.3s ease;
}
body[data-theme='light'] {
  --background-color: #fff;
  --text-color-primary: #000;
  --text-color-secondary: #333;
  --primary: rgb(0, 124, 237);
  --about-photo: #fff;
  --about-photo-shadow: 0 0 25px 0 rgb(0 0 0 / 10%);
  --text-color-gray: #666;
  --btn-background: #fff;
  --btn-box-shadow: 0 10px 10px -8px rgb(0 0 0 / 22%);
  --btn-primary-color: #222;
  --btn-secondary-color: #666;

  /* work */
  --item-period-bg: #fff;
  --item-company: #666;
  --timeline-item-after-color: #f5f6f9;
}

body[data-theme='dark'] {
  --background-color: #222;
  --text-color-primary: #fff;
  --text-color-secondary: #f5f5f5;
  --primary: rgb(0, 124, 237);
  --about-photo: #444;
  --about-photo-shadow: 0 0 25px 0 rgb(0 0 0 / 80%);
  --text-color-gray: #d5d5d5;
  --btn-background: #333;
  --btn-box-shadow: 0 10px 10px -8px rgb(0 0 0 / 78%);
  --btn-primary-color: #fff;
  --btn-secondary-color: #fff;

  /* work */
  --item-period-bg: #222;
  --item-company: #aaa;
  --timeline-item-after-color: #333;
}
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}
.btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding: 0.8em 2.1em;
  margin-bottom: 0.75em;
  margin-right: 0.25em;
  font-size: 14px;
  line-height: 1.2;
  border: 0;
  outline: 0;
  border-radius: 30px;
  font-family: poppins, Helvetica, sans-serif;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: var(--btn-box-shadow);
  background-color: var(--btn-background);
  text-decoration: none;
}
.btn-primary:hover {
  background-color: #007ced;
  color: #fff;
  border: 2px solid #007ced;
}
.btn-primary {
  border: 2px solid #007ced;
  color: var(--btn-primary-color);
  text-shadow: none;
}
.btn-secondary {
  border: 2px solid #d5d5d5;
  color: var(--btn-secondary-color);
}
.btn-secondary:hover {
  color: #333;
  border-color: #d5d5d5;
  background-color: #d5d5d5;
}
.block-header {
  color: var(--text-color-secondary);
  display: inline-block;
  position: relative;
  font-size: 21px;
  margin: 0 0 20px;
  z-index: 1;
  padding-bottom: 7px;
}
.block-header:before {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  background-color: #f5f5f5;
  height: 2px;
  bottom: 0;
}
.block-header:after {
  display: block;
  position: absolute;
  content: '';
  width: 30px;
  background-color: #007ced;
  height: 2px;
  bottom: 0;
}
