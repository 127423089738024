.contact {
  padding: 0 70px;
  margin-top: 70px;
  margin-bottom: 70px;
}
.contactItem {
  display: flex;
  align-items: center;
}
.contactItem h4 {
  margin: 0;
  color: var(--text-color-primary);
}
.contactItem p {
  margin: 0;
  color: var(--text-color-gray);
  font-size: 14px;
}
.info {
  margin-left: 30px;
}
.itemsGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 430px) {
  .contact {
    padding: 0 30px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .itemsGroup {
    flex-direction: column;
    align-items: flex-start;
  }
  .contactItem {
    margin-bottom: 20px;
  }
}
